import './App.css';
import ResponsiveAppBar from './firstPage';

function App() {
  return (
    <ResponsiveAppBar />
  );
}

export default App;
