import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box, Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';

function ResponsiveAppBar() {

    return (
        <div style={{ backgroundColor: '#DDEDE5', height: '100vh' }}>
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 700,
                                letterSpacing: '.1rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            MiiRA | Shop
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                sx={{ m: 3 }}
            ></Box>
            <Container>
                <Card sx={{ maxWidth: 250 }} raised>
                    <CardMedia
                        component="img"
                        image="/review-card.png"
                        title="Google Review Card"
                        sx={{
                            width: 'auto',
                            margin: "1em auto 0 auto",
                            height: "100%"
                        }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Google Review Card
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Create your custom Google Review card with QR Code and NFC tap with printed option. Also you can craft your own idea on it
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" href='tel:+18255940106'>call</Button>
                    </CardActions>
                </Card>
            </Container>
        </div>
    );
}
export default ResponsiveAppBar;
